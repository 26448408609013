<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')">
      <template v-slot:title>
        {{ data.name ? data.name : "Новый" }}
        <small v-if="id"> ({{ id }})</small>
      </template>
      <v-row>
        <v-col cols="12">
          <a-form-model v-model="data" :model="model" :errors="errors" @validate="validate($event)" />
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-btn v-if="id" @click="showTests = true">Вопросы</v-btn>

        <v-spacer></v-spacer>
        <a-btn-save @click="submit()"></a-btn-save>
        <a-btn-delete v-if="id" @click="removeDialogShow = true"></a-btn-delete>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
    <test-dialog v-if="id" v-model="showTests" :id="id" @save="saveTest($event)" :data="data" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl } from "../../components/mixings";

export default {
  mixins: [getForm, submitForm, removeEl],
  components: {
    testDialog: () => import("@/views/dialogs/infoTestDialog"),
  },
  props: {
    value: Boolean,
    id: Number,
  },
  data() {
    return {
      model: this.$store.getters["config/get"].models.info.form,
      api: "/7perfect/information",
      removeDialogShow: false,
      showTests: false,
    };
  },
  computed: {},
  watch: {
    value() {
      if (this.value) {
        this.updateData(this.id);
      }
    },
  },
  methods: {
    saveTest(e) {
      let data = { id: this.id, test_data: e };
      this.post(data, this.api);
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      if (status) this.$emit("input");
      // this.$router.push({name: "objects_view", params: {id: this.id}});
    },
  },
};
</script>
